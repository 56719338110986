import { BtnDanger } from '../common/ui-component';
import { useFormContext } from 'react-hook-form';

interface Props {
  formKey: string;
  newValue: string | null | undefined | (string | undefined)[];
}

const ClearValueButton = ({ formKey, newValue }: Props) => {
  const { setValue } = useFormContext();
  return (
    <BtnDanger
      sx={{ marginTop: '25px' }}
      onClick={() => {
        setValue(formKey, newValue);
      }}
    >
      X
    </BtnDanger>
  );
};

export default ClearValueButton;

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { TrackingEmail } from '../tracking-email/caution-form/email-sending.dto';
import { Header, Icons, Info, Link, Section, SectionContent, SuccessRemoteDataToast } from '../common/ui-component';
import OrderService from './orders.service';
import CustomerInfo from './CustomerInfo';
import { MEDICAL_CASE_ROUTE, ORDERS_ROUTE, SUBSCRIPTIONS_ROUTE } from '../Router';
import { EntryOrderLineModel, MedicalCaseModel, OrderModel } from '../models';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import { TrackingEmailList, TrackingEmailService } from '../tracking-email';
import { Container, Grid, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import { OrderStatusService } from '../order-status';
import { PharmacyList, PharmacyService } from '../pharmacy';
import { ConfirmRoutedModal, RoutedModal } from '../common/ui-component/modals';
import { ReplacementForm } from '../pharmacy/replacement';
import { cancelOrderHash, replaceOrderHash, resendOrderHash, shippingOrderHash } from '../pharmacy/SelectActionOptions';
import { map, notAsked, unwrap, withDefault } from '../utils/remote-data';
import { GridRowParams } from '@mui/x-data-grid';
import OrderActions from '../pharmacy/SelectActionOptions';
import { MdiService } from '../mdi';
import { DateHelper } from '../utils/date';
import MdiDetails from './MdiDetails';
import { getTrackingUrl, PharmacyOrder } from '../pharmacy/pharmacy-order.model';
import { Paginated } from '../common/Paginated';
import { ShippingForm } from '../pharmacy/shipping-form';
import MedicalCaseService from '../medical-case/medical-case.service';
import ResendForm from '../pharmacy/ResendForm';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import { Btn } from '../common/ui-component/buttons';

const Order = () => {
  const sendTrackingEmailHashModal = '#opensendemail';
  const sendTrackingEmailHash = '#sendmail';
  const syncOrderHash = '#sync';

  const { t } = useTranslation('order');
  const { t: tCommon } = useTranslation('common');
  const { id } = useParams();
  const currentRoute = `${ORDERS_ROUTE}/${id}`;
  const [getOrder, rOrder] = OrderService.useGetOrder();
  const navigate = useNavigate();

  const [pharmacyOrderToUpdateShipping, setPharmacyOrderToUpdateShipping] = useState<PharmacyOrder>();
  const [pharmacyOrderToCancel, setPharmacyOrderToCancel] = useState<PharmacyOrder>();
  const [pharmacyOrderToReplace, setPharmacyOrderToReplace] = useState<PharmacyOrder>();
  const [pharmacyOrderToResend, setPharmacyOrderToResend] = useState<PharmacyOrder>();

  const [getPharmacyOrders, rPharmacyOrders] = PharmacyService.useGetOrdersV2();
  const [getEmails, emails] = TrackingEmailService.useGetEmails();
  const [sendEmail, rSendEmail, setSendEmail] = TrackingEmailService.useSendEmail();

  const [trackingEmailToSend, setTrackingEmailToSend] = useState<TrackingEmail>();
  const [cancelPharmacyOrder, rCancelPharmacyOrder, setCancelPharmacyOrder] = PharmacyService.useCancelOrder();
  const [getOrderStatuses, rOrderStatuses] = OrderStatusService.useGetOrderStatuses();

  const [getPrescriptionsByCustomer, rPrescriptions] = OrderService.useGetPrescriptionsByCustomer();

  const [syncPrescriptions, rPrescriptionSyncResult, setPrescriptionSyncResult] = MdiService.useSyncPrescriptions();

  const [syncMdi, rMidSyncResult, setMdiSyncResult] = MdiService.useSyncCase();

  const [getMedicalCase, rMedicalCase] = MedicalCaseService.useGetMedicalCaseByCustomerId();

  const [syncOrderWithWoo, rSyncOrderWithWoo, setSyncResult] = OrderService.useSyncOrderWithWoo();

  const defaultParams = useMemo(() => ({ orderId: id, operator: 'equals' }), [id]);

  useEffect(() => {
    (async () => {
      getPharmacyOrders({
        pageNumber: 1,
        pageSize: 100,
        operator: 'equals',
        queries: {
          orderId: [{ value: id, operator: 'equals' }],
        },
      });
      const rBackofficeOrder = await getOrder(id);
      const customerId = unwrap((o: OrderModel) => o.customerId)(rBackofficeOrder);
      if (customerId) {
        getMedicalCase(customerId);
        getPrescriptionsByCustomer(customerId);
      }
      getOrderStatuses(id);
    })();
  }, [id, getOrder, getOrderStatuses, getPharmacyOrders, getMedicalCase, getPrescriptionsByCustomer]);

  const onSuccess = (close: () => void) => {
    close();
    getOrder(id);
  };

  const pharmacyDefaultParam: PageProps = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: 100,
      operator: 'equals',
      queries: {
        orderId: [{ value: id, operator: 'equals' }],
      },
    }),
    [id],
  );

  const trackingEmailAction = ({ row }: GridRowParams) => {
    return [
      <Link
        to={`${ORDERS_ROUTE}/${row.orderId}#sendmail`}
        onClick={() =>
          setTrackingEmailToSend({
            orderId: row.orderId,
            version: row.version,
            pharmacyName: row.pharmacyName,
          })
        }
      >
        <Icons.Send />
      </Link>,
    ];
  };

  return (
    <>
      <Container maxWidth="xl">
        <RemoteDataContent
          value={rOrder}
          whenSuccess={(order: OrderModel) => (
            <>
              <Header
                title={`${t('order')} #${id} ${order?.shippingAddress?.firstName} ${order?.shippingAddress?.lastName}`}
                details={[
                  {
                    id: order.parentId ? order.parentId.toString() : null,
                    href: `${ORDERS_ROUTE}/${order.parentId}`,
                    text: t('childOf'),
                  },
                  {
                    id: order.childId ? order.childId?.toString() : null,
                    href: `${ORDERS_ROUTE}/${order.childId}`,
                    text: t('parentOf'),
                  },
                  ...order.subscriptionIds.map((subId) => ({
                    id: subId.toString(),
                    href: `${SUBSCRIPTIONS_ROUTE}/${subId}`,
                    text: t('subscription'),
                  })),
                ].map((p: { id: string | null; text: string; href: string }) => (
                  <span style={{ marginRight: '1rem' }} key={id}>
                    {p.text}
                    {p.id === null ? 'n/a' : <Link href={p.href}>{` #${p.id}`}</Link>}
                  </span>
                ))}
              ></Header>
              <Grid container spacing={2} maxWidth="xl">
                <Grid item xs={8}>
                  <Section title={t('orderDetails')}>
                    <SectionContent>
                      <Info label={t('dateTime')}>{order?.orderCreated}</Info>

                      <Info label={t('wooCommerceId')}>
                        <Link
                          // eslint-disable-next-line max-len
                          href={`${process.env.REACT_APP_WOOCOMMERCE_URL}/post.php?post=${order?.woocommerceId}&action=edit`}
                        >
                          {order?.woocommerceId}
                        </Link>
                        <Btn
                          sx={{ width: '24px', minWidth: 0, margin: 0, padding: 0 }}
                          onClick={() => navigate(`${currentRoute}${syncOrderHash}`)}
                        >
                          <Tooltip title={t('syncOrderWithWoo')}>
                            <Icons.Sync />
                          </Tooltip>
                        </Btn>
                      </Info>
                      <Info label={t('medicalCaseId')}>
                        {withDefault(<span>--</span>)(
                          map((x: MedicalCaseModel) => <Link href={`${MEDICAL_CASE_ROUTE}/${x.id}`}>{x.id}</Link>)(
                            rMedicalCase,
                          ),
                        )}
                      </Info>
                    </SectionContent>
                    <SectionContent>
                      <Typography variant="subtitle2" display="block" my={1} sx={{ textDecoration: 'underline' }}>
                        {t('products')}
                      </Typography>
                    </SectionContent>
                    <>
                      {(order?.orderLines || []).map((el: EntryOrderLineModel) => (
                        <SectionContent key={el.id}>
                          <Info label={t('sku')}>{el.sku}</Info>
                          <Info label={t('quantityShort')}>{el.quantity}</Info>
                          <Info label={t('pharmacy')}>
                            {withDefault('--')(
                              map(
                                (pharmacyOrder: Paginated<PharmacyOrder>) =>
                                  pharmacyOrder.data.find((pharm: PharmacyOrder) =>
                                    pharm.rxItems.some((x) => x?.sku === el.sku),
                                  )?.pharmacyName ??
                                  pharmacyOrder.data.find((pharm: PharmacyOrder) =>
                                    pharm.otcItems.some((x) => x?.sku === el.sku),
                                  )?.pharmacyName ??
                                  '--',
                              )(rPharmacyOrders),
                            )}
                          </Info>
                          <Info label={t('trackingId')}>
                            <RemoteDataContent
                              value={rPharmacyOrders}
                              whenSuccess={(pharmacyOrders) => {
                                if (!pharmacyOrders.data.length) return <span>--</span>;
                                return (
                                  <div>
                                    {pharmacyOrders.data
                                      .filter(
                                        (pharm: PharmacyOrder) =>
                                          pharm.trackingNumber &&
                                          (pharm.rxItems.some((x) => x?.sku === el.sku) ||
                                            pharm.otcItems.some((x) => x?.sku === el.sku)),
                                      )
                                      .map((pharmacyOrder) => {
                                        return (
                                          <div>
                                            <Link
                                              to={getTrackingUrl(pharmacyOrder.carrier, pharmacyOrder.trackingNumber)}
                                            >
                                              {pharmacyOrder.trackingNumber}
                                            </Link>
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              }}
                            />
                          </Info>
                        </SectionContent>
                      ))}
                    </>
                  </Section>
                  <CustomerInfo order={order} />
                  <RemoteDataContent
                    value={rPrescriptions}
                    whenSuccess={(prescriptions) => (
                      <MdiDetails
                        order={order}
                        prescriptions={prescriptions.filter((p) =>
                          (order.orderLines || []).some((l) => l.sku === p.medication.metadata),
                        )}
                        syncPrescriptions={syncPrescriptions}
                        syncMdi={syncMdi}
                        caseId={order?.caseId ?? null}
                      />
                    )}
                  />
                  <PharmacyList
                    filterOut={['orderId', 'patientId', 'firstName', 'lastName']}
                    getOrders={getPharmacyOrders}
                    orders={rPharmacyOrders}
                    defaultParams={pharmacyDefaultParam}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RemoteDataContent
                    value={rPharmacyOrders}
                    whenSuccess={(pharmacyOrders) => {
                      return (
                        <OrderActions
                          pharmacyOrders={pharmacyOrders?.data}
                          currentRoute={currentRoute}
                          onCancel={setPharmacyOrderToCancel}
                          onReplace={setPharmacyOrderToReplace}
                          onShipped={setPharmacyOrderToUpdateShipping}
                          onResend={setPharmacyOrderToResend}
                        />
                      );
                    }}
                  />
                  <Section title={t('statusHistory')}>
                    <RemoteDataContent
                      value={rOrderStatuses}
                      whenSuccess={(orderStatuses) => {
                        return (
                          <List
                            sx={{
                              maxHeight: 820,
                              overflowY: 'scroll',
                              width: '100%',
                            }}
                          >
                            {orderStatuses.map((o) => (
                              <ListItem key={o.id}>
                                <ListItemText
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  primary={o.status}
                                  secondary={
                                    <span>
                                      <span style={{ display: 'block' }}>{`Source : ${o.source}`}</span>
                                      <span style={{ display: 'block' }}>{`Date : ${
                                        DateHelper.dateTime(o.created).value
                                      }`}</span>
                                    </span>
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        );
                      }}
                    />
                  </Section>
                </Grid>
              </Grid>

              <RoutedModal predicatedHash={shippingOrderHash} defaultUrl={currentRoute} title={t('pharmacyShipping')}>
                {(onClose: () => void) =>
                  !pharmacyOrderToUpdateShipping ? (
                    <p>{tCommon('weird')}</p>
                  ) : (
                    <ShippingForm
                      pharmacyOrder={pharmacyOrderToUpdateShipping}
                      onSuccess={() => onSuccess(onClose)}
                      close={onClose}
                    />
                  )
                }
              </RoutedModal>
              <RoutedModal predicatedHash={resendOrderHash} defaultUrl={currentRoute} title={t('pharmacyReplacement')}>
                {(onClose: () => void) =>
                  !pharmacyOrderToResend ? (
                    <p>{tCommon('weird')}</p>
                  ) : (
                    <ResendForm
                      pharmacyOrder={pharmacyOrderToResend}
                      onSuccess={() => onSuccess(onClose)}
                      close={onClose}
                    />
                  )
                }
              </RoutedModal>
              <RoutedModal predicatedHash={replaceOrderHash} defaultUrl={currentRoute} title={t('pharmacyReplacement')}>
                {(onClose: () => void) => (
                  <ReplacementForm
                    pharmacyOrder={pharmacyOrderToReplace}
                    onSuccess={() => onSuccess(onClose)}
                    close={onClose}
                  />
                )}
              </RoutedModal>
              <ConfirmRoutedModal
                predicatedHash={cancelOrderHash}
                defaultUrl={currentRoute}
                title={t('pharmacyCancel')}
                value={pharmacyOrderToCancel}
                onConfirm={(value, close) => {
                  cancelPharmacyOrder(value.id);
                  close();
                }}
              ></ConfirmRoutedModal>
              <ConfirmRoutedModal
                predicatedHash={sendTrackingEmailHash}
                defaultUrl={currentRoute}
                title={t('resendEmail')}
                value={trackingEmailToSend}
                onConfirm={(value, close) => {
                  sendEmail({
                    orderId: value.orderId,
                    version: value.version,
                    pharmacyName: value.pharmacyName,
                  });
                  close();
                }}
              ></ConfirmRoutedModal>
              <ConfirmRoutedModal
                predicatedHash={syncOrderHash}
                defaultUrl={currentRoute}
                title={t('syncOrderWithWoo')}
                value="a"
                onConfirm={async (value, close) => {
                  await syncOrderWithWoo(order.id);
                  close();
                }}
              ></ConfirmRoutedModal>
              <RoutedModal
                predicatedHash={sendTrackingEmailHashModal}
                defaultUrl={currentRoute}
                title={t('resendEmail')}
                maxWidth="lg"
              >
                {(onClose: () => void) => (
                  <TrackingEmailList
                    defaultParams={defaultParams}
                    getEmails={getEmails}
                    emails={emails}
                    getActions={trackingEmailAction}
                    trackingEmailToSend={trackingEmailToSend}
                    hasToolbar={false}
                    onClose={onClose}
                    currentRoute={currentRoute}
                  />
                )}
              </RoutedModal>
              <SuccessRemoteDataToast
                value={rSendEmail}
                onClick={() => {
                  setSendEmail(notAsked());
                }}
              ></SuccessRemoteDataToast>
              <SuccessRemoteDataToast
                value={rCancelPharmacyOrder}
                onClick={() => {
                  setCancelPharmacyOrder(notAsked());
                }}
              ></SuccessRemoteDataToast>
              <SuccessRemoteDataToast
                value={rMidSyncResult}
                onClick={() => {
                  setMdiSyncResult(notAsked());
                }}
              ></SuccessRemoteDataToast>
              <SuccessRemoteDataToast
                value={rPrescriptionSyncResult}
                onClick={() => {
                  setPrescriptionSyncResult(notAsked());
                }}
              ></SuccessRemoteDataToast>
              <SuccessRemoteDataToast
                value={rSyncOrderWithWoo}
                onClick={() => {
                  setSyncResult(notAsked());
                }}
              ></SuccessRemoteDataToast>

              {/* //TODO next task */}
              {/* <SuccessRemoteDataToast
              value={rMdiCase}
              onClick={() => {
                setMdiCaseResult(notAsked());
              }}
            ></SuccessRemoteDataToast> */}
            </>
          )}
        />
      </Container>
    </>
  );
};

export default Order;

import axios from 'axios';
import useService from '../common/useService';

export type Params = {
  dateOfBirth: string | null;
  userPreferredMethods: string[];
  userChosenPids: string[];
  gender: string | null;
};

export type Gender = 'Male' | 'Female';
export type Preference = 'none' | 'Oral' | 'Topical' | 'any';
export type UserChosenMethods = 'none' | 'Bundle' | 'Oral' | 'Topical';

export type Rule = {
  gender: Gender | 'any';
  over50: boolean | 'any';
  userPreferredMethods: Preference | 'any';
  userChosenMethods: UserChosenMethods;
  products: string[][];
};

export type RecommendationResult = { woocomerceId: number; sku: string }[][];

const useGetProductRecommendation = () =>
  useService((params: Params) =>
    axios.post<RecommendationResult>(`/product-recommendation`, params, {
      baseURL: process.env.REACT_APP_MIDDLEWARE_URL,
    }),
  );

const useGetRules = () =>
  useService((params: Params) =>
    axios.get<Rule[]>(`/product-recommendation/rules`, {
      baseURL: process.env.REACT_APP_MIDDLEWARE_URL,
    }),
  );

const ProductRecommendationService = {
  useGetProductRecommendation,
  useGetRules,
};

export default ProductRecommendationService;

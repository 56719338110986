import { Alert, Divider, Snackbar } from '@mui/material';
import { DateTime } from 'luxon';
import { ConnectedForm, DatePickerInput, DropdownInput, TextInput } from '../common/form-controller';
import {
  BtnDanger,
  BtnPrimary,
  FormSection,
  Icons,
  Link,
  Loader,
  Section,
  SectionContent,
} from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import { MdiService } from '../mdi';
import { isSuccess, loading, notAsked } from '../utils/remote-data';
import CaseService from './orders.service';
import MedicalCaseService from '../medical-case/medical-case.service';
import { ConfirmRoutedModal, RoutedModal } from '../common/ui-component/modals';
import { CASE_ROUTE } from '../Router';
import { isAdminUser } from '../authentication/auth';
import { useAccessToken } from '../authentication';
import MarketingPlaformService from '../marketing-platform/marketing-platform.service';
import { _databaseDateOnlyFormat } from '../utils/date/helper';
import { FileHelpers } from '../utils/file';
import ChemistryrxService from '../pharmacy/chemistryrx/chemistryrx.service';
import TrackingNumberCsvUploadSection from './TrackingNumberCsvUploadSection';
import ConciergeCallListUploadDownloadSection from './ConciergeCallListUploadDownloadSection';
import SubscriptionsService from '../subscriptions/subscriptions.service';
import RemoteDataContentV2 from '../common/ui-component/layouts/RemoteDataContent.v2';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

const hashCreateEncounterForHUDD = '#health-update-due-date-trigger';
const hashAddToHealthUpdateList = '#add-health-update-due-date-list-trigger';
const hashConciergeCall = '#concierge';
const CaseFixer = () => {
  const currentRoute = CASE_ROUTE;
  const { t } = useTranslation('case_fixer');
  const navigate = useNavigate();
  const [assignCase, result, setAssignAs] = CaseService.useAssignCase();
  const [regenerateCase, result2, setRegenAs] = CaseService.useRegenerateCase();

  const [updateCaseFromMdi, caseUpdateResult, setUpdateCaseAs] = MdiService.useSyncPrescriptions();

  const [triggerHealthUpdate, triggerResult, setTriggerReuslt] = MedicalCaseService.useTriggerHealthUpdates();

  const [triggerHealthUpdateList, triggerHealthUpdateListResult, setTriggerHealthUpdateListResult] =
    MarketingPlaformService.useTriggerHealthUpdateList();

  const [getCallList, callListResult, setCallList] = MedicalCaseService.useGetAnnualHealthUpdateCallList();
  const [user] = useAccessToken();

  const [uploadTrackingNumberCSV] = ChemistryrxService.useUploadTrackingNumberCSV();

  const [uploadConcierge, conciergeResult, setConciergeResult] = SubscriptionsService.useUploadDownloadConcierge();

  const uploadTrackingNumbers = async (file: File) => {
    uploadTrackingNumberCSV(file);
  };

  return (
    <>
      {isAdminUser(user) && (
        <>
          <Section title={'Set Case id'}>
            <SectionContent>
              <p>The order id should be the parent order id, use carefully pls</p>
            </SectionContent>

            <ConnectedForm
              defaultValues={{ caseId: '', orderId: '', status: undefined }}
              onSubmit={(v) => {
                setAssignAs(loading());
                assignCase(v);
              }}
            >
              <FormSection title={''}>
                <TextInput label="orderId" name="orderId"></TextInput>
                <TextInput label="caseId" name="caseId"></TextInput>
                <DropdownInput
                  label="Status"
                  items={[undefined, 'approved', 'in-review']}
                  name="status"
                  toOption={(tx) => ({
                    id: tx || '',
                    displayText: tx || 'No Status',
                    value: tx,
                  })}
                />
                <BtnPrimary type="submit">Send Case Id</BtnPrimary>
              </FormSection>
            </ConnectedForm>
          </Section>
          <Divider />
        </>
      )}
      <Section title="Try to send to pharmacy">
        <SectionContent>
          <p>
            Check if the order id status has change to pharmacy-order-sent, if not maybe its parent order has not been
            approved or order prescription doesn't exists or its already been shipped. Send us the order id and the
            reason pls
          </p>
        </SectionContent>

        <ConnectedForm
          defaultValues={{ caseId: '', orderId: '' }}
          onSubmit={(v) => {
            setRegenAs(loading());
            regenerateCase({ id: v.orderId });
          }}
        >
          <FormSection title={''}>
            <TextInput label="orderId" name="orderId"></TextInput>
            <BtnPrimary type="submit">Send To Pharmacy</BtnPrimary>
          </FormSection>
        </ConnectedForm>
      </Section>
      <Divider />

      <Section title="Retrieve case from MDI">
        <SectionContent>
          <p>Retrieve case, case's prescriptions from MDI then update the backoffice's database.</p>
        </SectionContent>

        <ConnectedForm
          defaultValues={{ caseId: '' }}
          onSubmit={(v) => {
            setUpdateCaseAs(loading());
            updateCaseFromMdi({ id: v.caseId });
          }}
        >
          <FormSection title={''}>
            <TextInput label="caseId" name="caseId"></TextInput>
            <BtnPrimary type="submit">Send To MDI</BtnPrimary>
          </FormSection>
        </ConnectedForm>
      </Section>
      <Divider />
      <Section title="Annual Health Update Call List">
        <SectionContent>
          <p>Create and download a CSV list of phone numbers for customers whose HUDD is 20 days from selected day.</p>
        </SectionContent>

        <ConnectedForm
          defaultValues={{ date: null }}
          onSubmit={async (v) => {
            if (!v.date) return;
            setCallList(loading());
            const formattedDate = DateTime.fromISO(v.date).toFormat(_databaseDateOnlyFormat);
            const blob = await getCallList(formattedDate);
            if (!isSuccess(blob)) return;
            FileHelpers.downloadBlob(blob.value, `call-list-generated-on_${formattedDate}.csv`);
          }}
        >
          <FormSection title={''}>
            <DatePickerInput required name="date" label="Date" />
            <BtnPrimary type="submit">Download Call List</BtnPrimary>
          </FormSection>
        </ConnectedForm>
      </Section>
      {isAdminUser(user) && (
        <>
          <Section title="Trigger create case for all health update due date for today">
            <Divider>
              <BtnDanger>
                <Link to={`${currentRoute}/${hashCreateEncounterForHUDD}`}>
                  <>
                    TRIGGER CREATE ENCOUNTER FOR HUDD FOR TODAY
                    <Icons.Bomb />
                  </>
                </Link>
              </BtnDanger>
            </Divider>
          </Section>
          <Section title="Trigger add to health update due list">
            <Divider>
              <BtnDanger>
                <Link to={`${currentRoute}/${hashAddToHealthUpdateList}`}>
                  <>
                    TRIGGER ADD TO HEALTH UPDATE DUE LIST
                    <Icons.Bomb />
                  </>
                </Link>
              </BtnDanger>
            </Divider>
          </Section>
        </>
      )}
      <TrackingNumberCsvUploadSection onUploadCSV={uploadTrackingNumbers} />
      <ConciergeCallListUploadDownloadSection
        onUploadCSV={(f) => {
          navigate(`${currentRoute}${hashConciergeCall}`);
          uploadConcierge(f);
        }}
      />
      <RemoteDataContent
        value={result}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setAssignAs(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <RemoteDataContent
        value={result2}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setRegenAs(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <RemoteDataContent
        value={caseUpdateResult}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setUpdateCaseAs(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <ConfirmRoutedModal
        predicatedHash={hashCreateEncounterForHUDD}
        defaultUrl={currentRoute}
        title={'Are you sure you want trigger case for all health update due today  ?'}
        value={'a'}
        onConfirm={(_, close) => {
          triggerHealthUpdate();
          close();
        }}
      ></ConfirmRoutedModal>
      <ConfirmRoutedModal
        predicatedHash={hashAddToHealthUpdateList}
        defaultUrl={currentRoute}
        title={'Are you sure you want trigger add to update due list ?'}
        value={'a'}
        onConfirm={(_, close) => {
          triggerHealthUpdateList();
          close();
        }}
      ></ConfirmRoutedModal>
      <RemoteDataContent
        value={triggerResult}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setTriggerReuslt(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <RemoteDataContent
        value={triggerHealthUpdateListResult}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setTriggerHealthUpdateListResult(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <RemoteDataContent
        value={callListResult}
        whenSuccess={() => {
          return (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setCallList(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          );
        }}
        whenNotAsked={() => <></>}
      />
      <RoutedModal
        title={''}
        predicatedHash={hashConciergeCall}
        defaultUrl={currentRoute}
        onClose={() => setConciergeResult(notAsked())}
      >
        {(onClose) => (
          <>
            <RemoteDataContentV2 value={conciergeResult}>
              <RemoteDataContentV2.Success<{ name: string; blob: Blob }>>
                {(data) => {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                      <a
                        href={URL.createObjectURL(data.blob)}
                        download={`${data.name}-with-subscriptions.csv`}
                        style={{ display: 'block', textDecoration: 'none', color: 'white' }}
                      >
                        <BtnPrimary sx={{ margin: '2rem' }}>{t('concierge.download')}</BtnPrimary>
                      </a>
                      <div>
                        <BtnDanger sx={{ margin: '2rem', maxWidth: '50px' }} onClick={onClose}>
                          {t('concierge.close')}
                        </BtnDanger>
                      </div>
                    </div>
                  );
                }}
              </RemoteDataContentV2.Success>
              <RemoteDataContentV2.NotAsked>
                <BtnPrimary onClick={onClose}>{t('concierge.close')} </BtnPrimary>
              </RemoteDataContentV2.NotAsked>
              <RemoteDataContentV2.Loading>
                <Loader />
              </RemoteDataContentV2.Loading>
              <RemoteDataContentV2.Failure>
                {(f) => {
                  if (isAxiosError(f)) {
                    return (
                      <>
                        {f.response?.data && 'message' in f.response?.data
                          ? f.response?.data.message
                          : JSON.stringify(f.response?.data)}
                        <div>
                          <BtnPrimary onClick={onClose}>{t('concierge.close')} </BtnPrimary>
                        </div>
                      </>
                    );
                  }

                  return (
                    <>
                      {JSON.stringify(f)}
                      <div>
                        <BtnPrimary onClick={onClose}>{t('concierge.close')} </BtnPrimary>
                      </div>
                    </>
                  );
                }}
              </RemoteDataContentV2.Failure>
            </RemoteDataContentV2>
          </>
        )}
      </RoutedModal>
    </>
  );
};

export default CaseFixer;

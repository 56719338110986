import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AddressContent } from '../common/address';
import {
  BtnPrimary,
  Dropdown,
  ErrorHandler,
  Form,
  FormRow,
  Info,
  Link,
  Page,
  SectionContent,
  StatusHistoryList,
  SuccessRemoteDataToast,
  Title,
} from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import RemoteDataContentV1 from '../common/ui-component/layouts/RemoteDataContent';
import Section from '../common/ui-component/layouts/Section.v2';
import { ConfirmRoutedModal, RoutedModal } from '../common/ui-component/modals';
import SuccessToast from '../common/ui-component/toasts/SuccessToast';
import { TooltipsV2 as Tooltips } from '../common/ui-component/tooltips';
import { PHARMACY_ORDER_ROUTE } from '../Router';
import { isSuccess, loading, notAsked } from '../utils/remote-data';
import {
  CancellableResendableError,
  getTrackingUrl,
  PharmacyNameEnum,
  PharmacyOrder,
  PharmacyOrderStatusEnum,
} from './pharmacy-order.model';
import PharmacyValidator from './pharmacy-order.validator';
import PharmacyService from './pharmacy.service';
import { ReplacementForm } from './replacement';
import { resendOrderHash } from './SelectActionOptions';
import { ShippingForm } from './shipping-form';
import ResendForm from './ResendForm';
import StatusChip from './StatusChip';
import { PharmacyOrderHistory } from '../models';
import { BtnUnstyled } from '../common/ui-component/buttons';
import ChemistryrxService from './chemistryrx/chemistryrx.service';
import { ChemistryrxOrder } from './chemistryrx/chemistryrx-order.model';
import CommentList from '../common/ui-component/CommentList';
import { ConnectedForm, DropdownInput, TextInput } from '../common/form-controller';
import { CommentDto } from './chemistryrx/comment.dto';
import axios from 'axios';
import { Alert, Snackbar } from '@mui/material';
import { FileHelpers } from '../utils/file';
import { isAdminUser } from '../authentication/auth';
import { useAccessToken } from '../authentication';

export const replaceOrderHash = '#replace';
export const cancelOrderHash = '#cancel';
export const shippingOrderHash = '#shipping';
const editTrackingNumberHash = '#edit-tracking-number';

const PharmacyErrors = ({ errors }: { errors: string[] }) => {
  const { t } = useTranslation('pharmacy');

  return errors.length ? (
    <Section.Sub>
      <Section.Title>{t('pharmacyErrors')}</Section.Title>
      <SectionContent>
        <div>
          {errors.map((error) => (
            <div style={{ color: 'red' }} key={error}>
              {error}
            </div>
          ))}
        </div>
      </SectionContent>
    </Section.Sub>
  ) : (
    <></>
  );
};

const DetailPage = () => {
  const { id } = useParams();
  const [user] = useAccessToken();
  const currentRoute = `${PHARMACY_ORDER_ROUTE}/${id}`;
  const { t } = useTranslation('pharmacy');

  const [fetchOrder, rOrder] = PharmacyService.useGetOrder();
  const [patchOrder] = PharmacyService.usePatch();
  const [fetchChemistryrxOrder, rChemistryrxOrder] = ChemistryrxService.useGetOrder();
  const [comment, rComment] = ChemistryrxService.useComment();
  const [downloadChemRxPdf, rChemistryRxPdf, setDownloadingPdf] = ChemistryrxService.useDownloadChemRxPdf();
  const [getHistory, rHistories] = PharmacyService.useGetPharmacyOrderHistory();

  const [cancelPharmacyOrder, rCancelPharmacyOrder, setCancelPharmacyOrder] = PharmacyService.useCancelOrder();

  const [showSuccess, toggleSuccess] = useState(false);

  const onSubmit = useCallback(
    async (commentDto: CommentDto) => {
      const o = await comment(commentDto);
      if (isSuccess(o)) {
        const { orderId, version } = o.value;

        fetchChemistryrxOrder({ orderId, version });
      }
    },
    [comment, fetchChemistryrxOrder],
  );

  const handleDownloadChemRxPdfClick = async (orderId: number, version: number) => {
    setDownloadingPdf(loading());
    const blob = await downloadChemRxPdf({ orderId, version });
    if (!isSuccess(blob)) return;
    FileHelpers.downloadBlob(blob.value, `ChemRX-#${orderId}-${version}.pdf`);
  };

  const handleTrackingNumberUpdate = (
    pharmacyOrder: {
      trackingNumber: string;
      status: PharmacyOrderStatusEnum;
    },
    pharmacyOrderId: number,
    onClose: () => void,
  ) => {
    patchOrder({
      id: pharmacyOrderId,
      pharmacyOrder,
    }).then(() => {
      onClose();
      fetchOrder(pharmacyOrderId);
    });
  };

  useEffect(() => {
    (async () => {
      if (id) {
        const o = await fetchOrder(+id);
        getHistory(+id);

        if (isSuccess(o)) {
          const { orderId, version, pharmacyName } = o.value;
          if (pharmacyName === PharmacyNameEnum.CHEMISTRY_RX) {
            fetchChemistryrxOrder({ orderId, version });
          }
        }
      }
    })();
  }, [fetchOrder, fetchChemistryrxOrder, getHistory, id]);

  return (
    <Page.Content>
      <RemoteDataContent value={rOrder}>
        <RemoteDataContent.Success>
          {(order: PharmacyOrder) => {
            const isResendable =
              !order.hasAlreadyBeenShipped &&
              order.status === 'error' &&
              CancellableResendableError.some((x) => x === order.statusDetails);
            const isAdmin = isAdminUser(user);
            return (
              <>
                <Page.Content.Split>
                  <Page.Content.Split.Main>
                    <Section key={`${order.pharmacyName} | ${order.orderId}-${order.version}`}>
                      <Section.Title>
                        <Title>
                          {`${order.firstName} ${order.lastName} - ${order.pharmacyName} - ${t('orderId')} #${
                            order.orderId
                          }-${order.version} `}
                          <StatusChip status={order.status} />
                        </Title>
                      </Section.Title>

                      <PharmacyErrors errors={PharmacyValidator.getAllErrors(order, t)} />
                      <Section.Sub title={t('detail')}>
                        <SectionContent>
                          <Info label={t('orderId')}>
                            <Link to={`/orders/${order.orderId}`}>{order.orderId}</Link>
                          </Info>
                          <Info label={t('created')}>{order.created || ''}</Info>
                          <Info label={t('updated')}>{order.updated || ''}</Info>
                        </SectionContent>
                      </Section.Sub>
                      <Section.Sub title={t('shipping')}>
                        <SectionContent>
                          <Info label={t('method')}>{order.shippingMethod || ''}</Info>
                          <Info label={t('trackingNumber')}>
                            {!order.trackingNumber ? (
                              '--'
                            ) : (
                              <Link to={getTrackingUrl(order.carrier, order.trackingNumber)}>
                                {order.trackingNumber}
                              </Link>
                            )}
                          </Info>
                        </SectionContent>
                      </Section.Sub>
                      <Section.Sub title={t('address')}>
                        <AddressContent
                          address={{
                            address1: order.overriddenAddress?.address1 || order.address1,
                            address2: order.overriddenAddress?.address2 || order.address2,
                            city: order.overriddenAddress?.city || order.city,
                            country: order.overriddenAddress?.country || order.country,
                            phone: order.overriddenAddress?.phone || order.phone,
                            postcode: order.overriddenAddress?.zip || order.zip,
                            state: order.overriddenAddress?.state || order.state,
                          }}
                        />
                      </Section.Sub>
                      <Section.Sub title={t('product')}>
                        <>
                          {order.rxItems.map((item) => (
                            <SectionContent key={item.sku}>
                              <Info label={t('sku')}>{item.sku || ''}</Info>
                              <Info label={t('medicationName')}>{item.prescription?.medicationName || ''}</Info>
                              <Info label={t('prescriber')}>{item.prescription?.prescribingDoctor || ''}</Info>
                            </SectionContent>
                          ))}
                          {order.otcItems.map((item) => (
                            <SectionContent key={item.sku}>
                              <Info label={t('sku')}>{item.sku || ''}</Info>
                              <Info label={t('medicationName')}>{item.name || ''}</Info>
                              <Info label={t('quantity')}>{item.quantity || ''}</Info>
                            </SectionContent>
                          ))}
                        </>
                      </Section.Sub>
                      <Section.Sub title={t('clinician')}>
                        <SectionContent>
                          <Info label={t('firstName')}>{order.clinician?.firstName || ''}</Info>
                          <Info label={t('lastName')}>{order.clinician?.lastName || ''}</Info>
                        </SectionContent>
                      </Section.Sub>
                    </Section>
                  </Page.Content.Split.Main>
                  <Page.Content.Split.Side>
                    <Section>
                      <Section.Title>{t('actions')}</Section.Title>
                      <Dropdown>
                        <Dropdown.Item>
                          <Tooltips>
                            <Tooltips.Conditional predicate={!isResendable}>
                              <span>{t('resendTooltip')}</span>
                            </Tooltips.Conditional>
                            <Tooltips.Content>
                              <BtnUnstyled disabled={!isResendable}>
                                <Link to={resendOrderHash} replace>
                                  {t('resend')}
                                </Link>
                              </BtnUnstyled>
                            </Tooltips.Content>
                          </Tooltips>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Tooltips>
                            <Tooltips.Conditional predicate={!order.hasAlreadyBeenShipped}>
                              <span>{t('replaceTooltip')}</span>
                            </Tooltips.Conditional>
                            <Tooltips.Content>
                              <BtnUnstyled disabled={!order.hasAlreadyBeenShipped}>
                                <Link to={replaceOrderHash} replace>
                                  {t('replace')}
                                </Link>
                              </BtnUnstyled>
                            </Tooltips.Content>
                          </Tooltips>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Tooltips>
                            <Tooltips.Conditional predicate={order.status === 'out_for_delivery'}>
                              <span>We can only Change Shipping before is out for delivery</span>
                            </Tooltips.Conditional>
                            <Tooltips.Content>
                              <BtnUnstyled disabled={order.status === 'out_for_delivery'}>
                                <Link to={shippingOrderHash} replace>
                                  {t('changeShipping')}
                                </Link>
                              </BtnUnstyled>
                            </Tooltips.Content>
                          </Tooltips>
                        </Dropdown.Item>
                        {order.pharmacyName === PharmacyNameEnum.CHEMISTRY_RX && (
                          <Dropdown.Item>
                            <Tooltips>
                              <Tooltips.Conditional predicate={order.status === 'not-sent'}>
                                <span>Impossible to download the PDF of an order that wasn't sent to Chemistry Rx</span>
                              </Tooltips.Conditional>
                              <Tooltips.Content>
                                <BtnUnstyled
                                  disabled={order.status === 'not-sent'}
                                  onClick={() => handleDownloadChemRxPdfClick(order.orderId, order.version)}
                                >
                                  {t('downloadPdf')}
                                </BtnUnstyled>
                              </Tooltips.Content>
                            </Tooltips>
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item>
                          <Tooltips>
                            <Tooltips.Conditional predicate={order.status === 'out_for_delivery'}>
                              <span>We can only cancel before is out for delivery</span>
                            </Tooltips.Conditional>
                            <Tooltips.Content>
                              <BtnUnstyled disabled={order.status === 'out_for_delivery'}>
                                <Link to={cancelOrderHash} replace>
                                  {t('cancel')}
                                </Link>
                              </BtnUnstyled>
                            </Tooltips.Content>
                          </Tooltips>
                        </Dropdown.Item>
                        {isAdmin && (
                          <Dropdown.Item>
                            <Tooltips>
                              <Tooltips.Content>
                                <BtnUnstyled>
                                  <Link to={editTrackingNumberHash} replace>
                                    {t('editTrackingNumber')}
                                  </Link>
                                </BtnUnstyled>
                              </Tooltips.Content>
                            </Tooltips>
                          </Dropdown.Item>
                        )}
                      </Dropdown>
                    </Section>
                    <Section>
                      <Section.Title>{t('history')}</Section.Title>
                      <RemoteDataContent value={rHistories}>
                        <RemoteDataContent.Success>
                          {(history: PharmacyOrderHistory[]) => (
                            <StatusHistoryList
                              history={history.flatMap((h, i) => {
                                if (h.new.status === h.old?.status) return [];
                                return [
                                  {
                                    created: h.created,
                                    id: i,
                                    source: '',
                                    status: h.new.status,
                                  },
                                ];
                              })}
                            />
                          )}
                        </RemoteDataContent.Success>
                      </RemoteDataContent>
                    </Section>
                    {order.pharmacyName === PharmacyNameEnum.CHEMISTRY_RX ? (
                      <Section>
                        <Section.Title>{t('messages')}</Section.Title>

                        <RemoteDataContent value={rChemistryrxOrder}>
                          <RemoteDataContent.Success>
                            {(co: ChemistryrxOrder) => (
                              <div style={{ width: '100%' }}>
                                <RemoteDataContent<ChemistryrxOrder> value={rComment}>
                                  <RemoteDataContent.Failure>
                                    {(f: unknown) => {
                                      if (axios.isAxiosError(f)) {
                                        switch (f.response?.status) {
                                          case 500:
                                            return <>{<Alert severity="error">{t('sftpError')}</Alert>}</>;
                                          case 404:
                                            return <>{<Alert severity="error">{t('notFoundError')}</Alert>}</>;
                                        }
                                      }
                                      return <ErrorHandler err={f} />;
                                    }}
                                  </RemoteDataContent.Failure>
                                  <RemoteDataContent.NotAsked>
                                    <></>
                                  </RemoteDataContent.NotAsked>
                                </RemoteDataContent>
                                <ConnectedForm
                                  defaultValues={{
                                    orderId: co.orderId,
                                    version: co.version,
                                    text: '',
                                  }}
                                  onSubmit={(formValue) => onSubmit(formValue)}
                                >
                                  <FormRow>
                                    <TextInput name="text" label={t('comment')} />
                                  </FormRow>

                                  <BtnPrimary sx={{ m: 0 }} type="submit">
                                    {t('send')}
                                  </BtnPrimary>
                                </ConnectedForm>
                                {co.comments.length && <CommentList comments={co.comments} />}
                              </div>
                            )}
                          </RemoteDataContent.Success>
                        </RemoteDataContent>
                      </Section>
                    ) : (
                      <></>
                    )}
                  </Page.Content.Split.Side>
                </Page.Content.Split>

                <SuccessRemoteDataToast
                  value={rCancelPharmacyOrder}
                  onClick={() => {
                    setCancelPharmacyOrder(notAsked());
                  }}
                />
                {showSuccess ? <SuccessToast onClick={() => toggleSuccess(false)} /> : <></>}

                <RoutedModal predicatedHash={shippingOrderHash} defaultUrl={currentRoute} title={t('shipping')}>
                  {(onClose: () => void) => <ShippingForm pharmacyOrder={order} onSuccess={onClose} close={onClose} />}
                </RoutedModal>
                <RoutedModal predicatedHash={replaceOrderHash} defaultUrl={currentRoute} title={t('replacement')}>
                  {(onClose: () => void) => (
                    <ReplacementForm pharmacyOrder={order} onSuccess={onClose} close={onClose} />
                  )}
                </RoutedModal>

                <RoutedModal predicatedHash={resendOrderHash} defaultUrl={currentRoute} title={t('resend')}>
                  {(onClose: () => void) => <ResendForm pharmacyOrder={order} onSuccess={onClose} close={onClose} />}
                </RoutedModal>
                <ConfirmRoutedModal
                  predicatedHash={cancelOrderHash}
                  defaultUrl={currentRoute}
                  title={t('cancel')}
                  value={order}
                  onConfirm={(value, close) => {
                    cancelPharmacyOrder(value.id);
                    close();
                  }}
                ></ConfirmRoutedModal>
                <RoutedModal
                  predicatedHash={editTrackingNumberHash}
                  defaultUrl={currentRoute}
                  title={t('editTrackingNumber')}
                >
                  {(onClose: () => void) => (
                    <ConnectedForm
                      id={editTrackingNumberHash}
                      onSubmit={(payload: { trackingNumber: string; status: PharmacyOrderStatusEnum }) =>
                        handleTrackingNumberUpdate(payload, order.id, onClose)
                      }
                      defaultValues={{
                        trackingNumber: order.trackingNumber,
                      }}
                    >
                      <Form.Section title="">
                        <Form.Section.Row>
                          <TextInput name="trackingNumber" label={t('trackingNumber')} />
                        </Form.Section.Row>
                      </Form.Section>
                      <Form.Section title="">
                        <Form.Section.Row>
                          <DropdownInput
                            label={t('status')}
                            items={Object.values(PharmacyOrderStatusEnum)}
                            name="status"
                            toOption={(tx) => ({
                              id: tx || '',
                              displayText: tx || 'No Status',
                              value: tx,
                            })}
                          />
                        </Form.Section.Row>
                      </Form.Section>
                      <Form.Section title="">
                        <Form.Section.Row>
                          <BtnPrimary type="submit">{t('update')}</BtnPrimary>
                        </Form.Section.Row>
                      </Form.Section>
                    </ConnectedForm>
                  )}
                </RoutedModal>
              </>
            );
          }}
        </RemoteDataContent.Success>
      </RemoteDataContent>
      <RemoteDataContentV1
        value={rChemistryRxPdf}
        whenSuccess={() => {
          return (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setDownloadingPdf(notAsked());
                }}
              >
                {'PDF download was successful -> click on me'}
              </Alert>
            </Snackbar>
          );
        }}
        whenNotAsked={() => <></>}
      />
    </Page.Content>
  );
};

export default DetailPage;

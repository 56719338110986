import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type ObjectValuesProps<T, V> = {
  values: V[];
  label: string;
  toOption: (t: T) => { displayText: string; value: V };
  items: T[];
  onChange: (t: V[] | undefined) => void;
  readonly?: boolean;
  required?: boolean;
  getIdFromValue: ((value: V) => string) | undefined;
};

type Props<T, V> =
  | (Omit<ObjectValuesProps<T, V>, 'getIdFromValue'> & { getIdFromValue?: undefined })
  | ObjectValuesProps<T, V>;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 70 * 4.5 + 8,
    },
  },
};

const MultipleDropdownFieldV2 = <T, V>(props: Props<T, V>) => {
  const { values, label, toOption, items, onChange, readonly, required } = props;

  const getId = (value: V): string => {
    if ('getIdFromValue' in props && props.getIdFromValue) {
      return props.getIdFromValue(value);
    }

    if (typeof value === 'string') return value;
    if (typeof value === 'number') return value.toString();

    throw new Error('Cannot identify the id');
  };

  const handleChange = (
    event:
      | (Event & {
          target: {
            value: string | number | readonly string[] | undefined;
            name: string;
          };
        })
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedValues = event.target.value;

    if (Array.isArray(selectedValues)) {
      const selected = items
        .filter((item) =>
          selectedValues.some((value) => {
            return getId(value) === getId(toOption(item).value);
          }),
        )
        .map((x) => toOption(x).value);
      onChange(selected);
      return;
    }

    onChange(undefined);
  };
  const current_value = items.flatMap((item) => {
    return values.some((value) => getId(value) === getId(toOption(item).value)) && getId(toOption(item).value)
      ? [getId(toOption(item).value)]
      : [];
  });

  return (
    <FormControl fullWidth margin="normal">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={current_value}
        label={label}
        onChange={handleChange}
        readOnly={readonly}
        multiple
        required={required}
        MenuProps={MenuProps}
      >
        {items.map((option: T) => {
          const opt = toOption(option);
          return (
            <MenuItem key={getId(opt.value)} value={getId(opt.value)}>
              {opt.displayText}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MultipleDropdownFieldV2;

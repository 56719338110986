import axios from 'axios';
import useService from '../common/useService';
import { Product } from './product.entity';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import { Paginated } from '../common/Paginated';

const useGetProducts = () => useService(() => axios.get<Product[]>(`/products`));

const useSearchProducts = () =>
  useService((params: PageProps) => axios.get<Paginated<Product>>(`/products/search`, { params }));

const useSyncFromWoo = () => useService((id: string) => axios.post(`/woocommerce/product/${id}/sync`));

const useProductSync = () => useService((id: string) => axios.post(`/products/${id}/sync`));

const useSearchProductsWithBlueshiftSync = () =>
  useService(async (params: PageProps) => {
    const paginatedProducts = await axios.get<Paginated<Product>>(`/products/search`, { params });

    const blueshiftProducts = await axios.get<Paginated<{ id: number; productId: number }>>(
      '/blueshift-middleware/catalog/products',
      {
        params: {
          queries: { id: [{ operator: 'in', value: paginatedProducts.data.data.map((x) => x.id) }] },
          pageNumber: 1,
          pageSize: 20,
        },
      },
    );

    return {
      ...blueshiftProducts,
      data: {
        pagination: paginatedProducts.data.pagination,
        data: paginatedProducts.data.data.map((p) => ({
          ...p,
          inBlueshift: blueshiftProducts.data.data.some((b) => b.id === p.id),
        })),
      },
    };
  });

const ProductService = {
  useGetProducts,
  useSearchProducts,
  useSyncFromWoo,
  useProductSync,
  useSearchProductsWithBlueshiftSync,
};

export default ProductService;

import { PharmacyNameEnum } from '../pharmacy-order.model';

export interface OrderShipping {
  orderId: number;
  version: number;
  carrier: Carrier;
  shippingMethod: ShippingMethods;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  pharmacyName: string;
}

export enum CarrierEnum {
  USPS = 'USPS',
  fedex = 'fedex',
}

export type CarrierServiceProvider = USPS | Fedex | Stamp;

export type USPS = {
  name: CarrierEnum.USPS;
  serviceCodes: ['usps_first', 'usps_priority'];
  defaultService: 'usps_first';
};

export type Fedex = { name: CarrierEnum.fedex; serviceCodes: ['fedex_2day']; defaultService: 'fedex_2day' };

export type Stamp = {
  name: CarrierEnum.USPS;
  serviceCodes: ['usps_ground_advantage'];
  defaultService: 'usps_ground_advantage';
};

export type PharmacyCarrierServiceProvider =
  | {
      pharmacy: PharmacyNameEnum.CHEMISTRY_RX;
      carriers: [USPS];
    }
  | {
      pharmacy: PharmacyNameEnum.HAPPYHEAD;
      carriers: [USPS, Fedex];
    }
  | {
      pharmacy: PharmacyNameEnum.CUREXA;
      carriers: [USPS];
    }
  | {
      pharmacy: PharmacyNameEnum.MINIMUS;
      carriers: [Stamp, Fedex];
    };

const pharmacyCarriers: PharmacyCarrierServiceProvider[] = [
  {
    pharmacy: PharmacyNameEnum.CHEMISTRY_RX,
    carriers: [{ name: CarrierEnum.USPS, serviceCodes: ['usps_first', 'usps_priority'], defaultService: 'usps_first' }],
  },

  {
    pharmacy: PharmacyNameEnum.HAPPYHEAD,
    carriers: [
      {
        name: CarrierEnum.USPS,
        serviceCodes: ['usps_first', 'usps_priority'],
        defaultService: 'usps_first',
      },
      { name: CarrierEnum.fedex, serviceCodes: ['fedex_2day'], defaultService: 'fedex_2day' },
    ],
  },
  {
    pharmacy: PharmacyNameEnum.CUREXA,
    carriers: [
      {
        name: CarrierEnum.USPS,
        serviceCodes: ['usps_first', 'usps_priority'],
        defaultService: 'usps_first',
      },
    ],
  },
  {
    pharmacy: PharmacyNameEnum.MINIMUS,
    carriers: [
      {
        name: CarrierEnum.USPS,
        serviceCodes: ['usps_ground_advantage'],
        defaultService: 'usps_ground_advantage',
      },
      {
        name: CarrierEnum.fedex,
        serviceCodes: ['fedex_2day'],
        defaultService: 'fedex_2day',
      },
    ],
  },
];

export type PharmacyProvider =
  | { pharmacy: PharmacyNameEnum.CHEMISTRY_RX; carrier: CarrierEnum.USPS }
  | { pharmacy: PharmacyNameEnum.HAPPYHEAD; carrier: CarrierEnum.USPS }
  | { pharmacy: PharmacyNameEnum.HAPPYHEAD; carrier: CarrierEnum.fedex }
  | { pharmacy: PharmacyNameEnum.CUREXA; carrier: CarrierEnum.USPS }
  | { pharmacy: PharmacyNameEnum.MINIMUS; carrier: CarrierEnum.USPS }
  | { pharmacy: PharmacyNameEnum.MINIMUS; carrier: CarrierEnum.fedex };

export type Carrier = keyof typeof CarrierEnum;

export type ShippingMethods = UspsShippingMethod | FedexShippingMethod;

export type UspsShippingMethod = 'usps_first' | 'usps_priority' | 'usps_ground_advantage';
export type FedexShippingMethod = 'fedex_2day';

export const getPharmacyCarriers = (pharmacy: PharmacyNameEnum): PharmacyCarrierServiceProvider => {
  const r = pharmacyCarriers.find((x) => x.pharmacy === pharmacy);
  if (!r) {
    throw new Error(`Pharmacy ${pharmacy} not found`);
  }
  return r;
};

export const getDefaultShippingMethodsByCarrier = (
  pharmacy: PharmacyNameEnum,
  carrier: CarrierEnum,
): string | undefined => {
  const r = pharmacyCarriers.find((x) => x.pharmacy === pharmacy);
  if (!r) {
    throw new Error(`Pharmacy ${pharmacy} not found`);
  }
  return r.carriers.find((x) => x.name === carrier)?.defaultService;
};

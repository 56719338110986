import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getDefaultShippingMethodsByCarrier, PharmacyCarrierServiceProvider } from './order-shipping.dto';
import { DropdownInput } from '../../common/form-controller';
export const ShippingMethodsDropdown = ({
  pharmacyCarrier,
}: {
  pharmacyCarrier: PharmacyCarrierServiceProvider | undefined;
}) => {
  const { t } = useTranslation('pharmacy');

  useWatch({ name: 'carrier' });
  const shippingForm = useFormContext();

  const carrier = shippingForm.getValues('carrier');
  useEffect(() => {
    if (!pharmacyCarrier) return;
    shippingForm.setValue('shippingMethod', getDefaultShippingMethodsByCarrier(pharmacyCarrier?.pharmacy, carrier));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrier]);

  return (
    <DropdownInput
      name="shippingMethod"
      label={t('shippingMethod')}
      items={pharmacyCarrier?.carriers.find((x) => x.name === carrier)?.serviceCodes || []}
      toOption={(x) => ({
        displayText: x,
        value: x,
        id: x,
      })}
    />
  );
};

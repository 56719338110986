import { DataTablePro as DataTable, Icons, Link } from '../common/ui-component';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { DateFormatter } from '../common/ui-component/data-table';
import SubscriptionStatusBadge from './SubscriptionStatusBadge';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { RemoteData } from '../utils/remote-data';
import { Paginated } from '../common/Paginated';
import { DefaultQueryParams } from '../utils/query';
import { SubscriptionLineItemModel, SubscriptionModel } from '../models';

const getActions = ({ id }: GridRowParams) => [
  <Link to={`/subscriptions/${id}`}>
    <Icons.Edit />
  </Link>,
];

const getColumns = (t: (key: string) => string): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: t('id'),
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: t('status'),
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return <SubscriptionStatusBadge status={params.value} />;
      },
    },
    {
      field: 'woocommerceId',
      headerName: t('subscriptionId'),
      headerAlign: 'center',
      minWidth: 120,
    },
    {
      field: 'customerId',
      headerName: t('customerId'),
      headerAlign: 'center',
    },
    {
      field: 'customerName',
      headerName: t('customerName'),
      headerAlign: 'center',
      minWidth: 140,
    },
    {
      field: 'phone',
      headerName: t('phone'),
      headerAlign: 'center',
      minWidth: 140,
    },
    {
      field: 'email',
      headerName: t('email'),
      headerAlign: 'center',
      minWidth: 140,
    },
    {
      field: 'lineItems',
      headerName: t('sku'),
      headerAlign: 'center',
      filterOperators: FilterOperator.getGridArrayOperators(),
      renderCell: (params: GridRenderCellParams) => {
        return (params.value || []).map((x: SubscriptionLineItemModel) => x.sku).join(', ');
      },
    },
    {
      field: 'lastOrderDate',
      headerName: t('lastOrderDate'),
      headerAlign: 'center',
      minWidth: 130,
      valueFormatter: DateFormatter.normalize,
      filterOperators: FilterOperator.getGridDateOperators(),
    },
    {
      field: 'lastOrderDateWithTime',
      headerName: t('lastOrderDateWithTime'),
      headerAlign: 'center',
      minWidth: 130,
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'lastPaymentDate',
      headerName: t('lastPaymentDate'),
      headerAlign: 'center',
      minWidth: 110,
      valueFormatter: DateFormatter.normalize,
      filterOperators: FilterOperator.getGridDateOperators(),
    },
    {
      field: 'lastPaymentDateWithTime',
      headerName: t('lastPaymentDateWithTime'),
      headerAlign: 'center',
      minWidth: 110,
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'nextPaymentDate',
      headerName: t('nextPaymentDate'),
      headerAlign: 'center',
      minWidth: 110,
      valueFormatter: DateFormatter.normalize,
      filterOperators: FilterOperator.getGridDateOperators(),
    },
    {
      field: 'nextPaymentDateWithTime',
      headerName: t('nextPaymentDateWithTime'),
      headerAlign: 'center',
      minWidth: 110,
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'category',
      headerName: t('category'),
      filterOperators: FilterOperator.getGridSelectOperators(),
      type: 'singleSelect',
      valueOptions: [
        { value: 'Rx', label: t('rx') },
        { value: 'Otc', label: t('otc') },
        { value: 'Both', label: t('both') },
      ],
    },
    {
      field: 'virtualNumberOfItems',
      headerName: t('numberOfProducts'),
      headerAlign: 'center',
    },
    {
      field: 'dateCreated',
      headerName: t('dateCreated'),
      type: 'number',
      minWidth: 155,
      headerAlign: 'center',
      valueFormatter: DateFormatter.normalize,
      filterOperators: FilterOperator.getGridDateOperators(),
    },
    {
      field: 'dateCreatedWithTime',
      headerName: t('dateCreatedWithTime'),
      type: 'number',
      minWidth: 155,
      headerAlign: 'center',
      valueFormatter: DateFormatter.withTime,
      filterable: false,
      sortable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      getActions,
    },
  ];
};
interface Props {
  listId: string;
  defaultParams?: DefaultQueryParams;
  getSubscriptions: (params: unknown) => Promise<RemoteData<Paginated<SubscriptionModel>, unknown>>;
  subscriptions: RemoteData<Paginated<SubscriptionModel>, unknown>;
  hasToolbar?: boolean;
  checkboxSelection?: boolean;
}

function SubscriptionList({
  listId,
  getSubscriptions,
  subscriptions,
  hasToolbar = true,
  checkboxSelection = true,
}: Props) {
  const { t } = useTranslation('subscription');

  return (
    <DataTable
      id={listId}
      cols={getColumns(t)}
      fetcher={getSubscriptions}
      datas={subscriptions}
      toRow={(subscription: SubscriptionModel) => ({
        ...subscription,
        dateCreatedWithTime: subscription.dateCreated,
        nextPaymentDateWithTime: subscription.nextPaymentDate,
        lastPaymentDateWithTime: subscription.lastPaymentDate,
        lastOrderDateWithTime: subscription.lastOrderDate,
        headerAlign: 'center',
      })}
      toCsvRow={(subscription: SubscriptionModel) => ({
        ...subscription,
        lineItems: (subscription.lineItems || []).map((x) => x.sku).join(', '),
        headerAlign: 'center',
      })}
      initialColumnVisibilityModel={{
        dateCreatedWithTime: false,
        nextPaymentDateWithTime: false,
        lastPaymentDateWithTime: false,
        lastOrderDateWithTime: false,
      }}
      hasToolbar={hasToolbar}
      checkboxSelection={checkboxSelection}
    />
  );
}

export default SubscriptionList;

import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedForm, DropdownInput, TextInput } from '../common/form-controller';
import { map, withDefault } from '../utils/remote-data';
import { BtnDanger, BtnPrimary, FormRow } from '../common/ui-component';
import { FormSection } from '../common/ui-component';
import { getPharmacyCarriers, OrderShipping } from './shipping-form/order-shipping.dto';
import { PharmacyOrder } from './pharmacy-order.model';
import PharmacyService from './pharmacy.service';
import { ShippingMethodsDropdown } from './shipping-form/ShippingMethodsDropdown';

interface Props {
  pharmacyOrder: PharmacyOrder;
  onSuccess: (order: PharmacyOrder) => void;
  close: () => void;
}

const ResendForm = ({ pharmacyOrder, onSuccess, close }: Props) => {
  const { t } = useTranslation(['pharmacy', 'common']);
  const [shippingOrder, rOrder] = PharmacyService.useResendOrder();
  const onSubmit = useCallback(
    (orderShippingDto: OrderShipping, id: number) => {
      shippingOrder({ id, orderShipping: orderShippingDto });
    },
    [shippingOrder],
  );
  const pharmacyCarriers = getPharmacyCarriers(pharmacyOrder.pharmacyName);

  useEffect(() => {
    const nothing = () => {};
    const rFn = map((o: PharmacyOrder) => () => onSuccess(o))(rOrder);
    const fn = withDefault(nothing)(rFn);
    fn();
  }, [rOrder, onSuccess]);

  return !pharmacyOrder ? (
    <>
      <p>{t('common:weird')}</p>
      <BtnDanger sx={{ m: 0 }} onClick={close}>
        {t('pharmacy:close')}
      </BtnDanger>
    </>
  ) : (
    <ConnectedForm<OrderShipping>
      defaultValues={{
        orderId: pharmacyOrder.orderId,
        version: pharmacyOrder.version,
        carrier: pharmacyCarriers.carriers[0].name,
        shippingMethod: pharmacyCarriers.carriers[0].defaultService,
        address1: pharmacyOrder.address1,
        address2: pharmacyOrder.address2,
        city: pharmacyOrder.city,
        country: pharmacyOrder.country,
        state: pharmacyOrder.state,
        zip: pharmacyOrder.zip,
        pharmacyName: pharmacyOrder.pharmacyName,
      }}
      onSubmit={(formValue) => onSubmit(formValue, pharmacyOrder.id)}
    >
      <FormSection title={t('pharmacy:shippingDetails')}>
        <FormRow>
          <TextInput readOnly name="orderId" label={t('pharmacy:orderId')} />
          <TextInput readOnly name="version" label={t('pharmacy:version')} />
          <TextInput readOnly name="pharmacyName" label={t('pharmacy:pharmacy')} />
        </FormRow>
        <FormRow>
          <DropdownInput
            name="carrier"
            label={t('pharmacy:carrier')}
            items={pharmacyCarriers.carriers.map((c) => c.name)}
            toOption={(x) => ({
              displayText: x,
              value: x,
              id: x,
            })}
          />
        </FormRow>
        <FormRow>
          <ShippingMethodsDropdown pharmacyCarrier={pharmacyCarriers} />
        </FormRow>
      </FormSection>
      <FormSection title={t('pharmacy:shippingAddress')}>
        <FormRow>
          <TextInput required name="address1" label={t('pharmacy:address1')} />
          <TextInput name="address2" label={t('pharmacy:address2')} />
          <TextInput required name="city" label={t('pharmacy:city')} />
        </FormRow>
        <FormRow>
          <TextInput required name="state" label={t('pharmacy:state')} />
          <TextInput required name="zip" label={t('pharmacy:zip')} />
          <TextInput required name="country" label={t('pharmacy:country')} />
        </FormRow>
      </FormSection>
      <BtnPrimary sx={{ m: 0 }} type="submit">
        {t('pharmacy:send')}
      </BtnPrimary>
    </ConnectedForm>
  );
};

export default ResendForm;

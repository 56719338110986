import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CheckboxInput,
  ConnectedForm,
  DropdownInput,
  MultipleDropdownInput,
  TextInput,
} from '../../common/form-controller';
import TextAreaInput from '../../common/form-controller/TextAreaInput';
import { map, withDefault } from '../../utils/remote-data';
import { BtnDanger, BtnPrimary, FormRow, FormSection } from '../../common/ui-component';
import { ReplacementDTO, ReplacementOtcItem, ReplacementRxItem } from './replace-order.dto';
import { useFormContext, useWatch } from 'react-hook-form';
import { PharmacyAddress, PharmacyNameEnum, PharmacyOrder, RxItem } from '../pharmacy-order.model';
import PharmacyService from '../pharmacy.service';

const AddressParts = ({ address }: { address: PharmacyAddress }) => {
  const { t } = useTranslation('pharmacy');
  useWatch({ name: 'replacementParty' });
  const replaceOrderForm = useFormContext();
  replaceOrderForm.setValue('address1', address.address1);
  replaceOrderForm.setValue('address2', address.address2);
  replaceOrderForm.setValue('city', address.city);
  replaceOrderForm.setValue('country', address.country);
  replaceOrderForm.setValue('phone', address.phone);
  replaceOrderForm.setValue('state', address.state);
  replaceOrderForm.setValue('zip', address.zip);

  return (
    <FormSection title={t('replacementAddress')}>
      <FormRow>
        <TextInput required name="address1" label={t('address1')} />
        <TextInput name="address2" label={t('address2')} />
        <TextInput required name="city" label={t('city')} />
      </FormRow>
      <FormRow>
        <TextInput required name="state" label={t('state')} />
        <TextInput required name="zip" label={t('zip')} />
        <TextInput required name="country" label={t('country')} />
      </FormRow>
    </FormSection>
  );
};

const mapToRxDto = (rxItem: RxItem): ReplacementRxItem => ({
  rxId: rxItem.rxId,
  medicationName: rxItem.prescription.medicationName,
  quantityDispensed: rxItem.prescription.quantityDispensed,
  daysSupply: rxItem.prescription.daysSupply,
  prescribingDoctor: rxItem.prescription.prescribingDoctor,
  medicationSig: rxItem.prescription.medicationSig,
  isRefill: rxItem.prescription.isRefill,
  nonChildResistantAcknowledgment: rxItem.prescription.nonChildResistantAcknowledgment,
  sku: rxItem.sku,
});

const mapItems = (replacementDto: ReplacementDTO): ReplacementDTO => ({
  ...replacementDto,
  otcItems: (replacementDto?.items?.filter((x) => !x.hasOwnProperty('rxId')) || []) as ReplacementOtcItem[],
  rxItems: (replacementDto?.items?.filter((x) => x.hasOwnProperty('rxId')) || []) as ReplacementRxItem[],
  items: undefined,
});

interface Props {
  pharmacyOrder: PharmacyOrder | undefined;
  onSuccess: (order: PharmacyOrder) => void;
  close: () => void;
}

const ReplacementForm = ({ pharmacyOrder, onSuccess, close }: Props) => {
  const { t } = useTranslation(['pharmacy', 'common']);
  const [replaceOrder, rOrder] = PharmacyService.useReplaceOrder();
  const items: (ReplacementRxItem | ReplacementOtcItem)[] = [
    ...(pharmacyOrder?.rxItems || []).map(mapToRxDto),
    ...(pharmacyOrder?.otcItems || []),
  ];

  const onSubmit = useCallback(
    (replacementDTO: ReplacementDTO, id: number) => {
      replaceOrder({ id, replacement: mapItems(replacementDTO) });
    },
    [replaceOrder],
  );

  useEffect(() => {
    const nothing = () => {};
    const rFn = map((o: PharmacyOrder) => () => onSuccess(o))(rOrder);
    const fn = withDefault(nothing)(rFn);
    fn();
  }, [rOrder, onSuccess]);

  return !pharmacyOrder ? (
    <>
      <p>{t('common:weird')}</p>
      <BtnDanger sx={{ m: 0 }} onClick={close}>
        {t('pharmacy:close')}
      </BtnDanger>
    </>
  ) : (
    <ConnectedForm
      defaultValues={{
        orderId: pharmacyOrder.orderId,
        version: pharmacyOrder.version,
        replacementParty:
          pharmacyOrder.pharmacyName === PharmacyNameEnum.CUREXA
            ? { displayText: 'Lost/Stolen – Equal', value: '1' }
            : undefined,
        replacementReason: '',
        items: [],
        otcItems: [],
        rxItems: [],
        pharmacyName: pharmacyOrder.pharmacyName,
        expediteReplacement: true,
      }}
      onSubmit={(formValue) => onSubmit(formValue, pharmacyOrder.id)}
    >
      <FormSection title={t('pharmacy:replacementDetails')}>
        <FormRow>
          <TextInput readOnly name="orderId" label={t('pharmacy:orderId')} />
          <TextInput readOnly name="version" label={t('pharmacy:version')} />
          <TextInput readOnly name="pharmacyName" label={t('pharmacy:pharmacy')} />
        </FormRow>
        <FormRow>
          <MultipleDropdownInput
            name="items"
            label={t('pharmacy:items')}
            items={items.filter((x) => !x.replaced)}
            toOption={(x) => {
              return {
                displayText: 'name' in x ? x.name : x.sku ? x.sku : x.medicationName,
                value: x,
                id: 'name' in x ? x.name : x.sku ? x.sku : x.medicationName,
              };
            }}
          />
        </FormRow>
        {pharmacyOrder.pharmacyName === PharmacyNameEnum.CUREXA && (
          <FormRow>
            <DropdownInput
              name="replacementParty"
              label={t('pharmacy:party')}
              items={[
                { displayText: 'Lost/Stolen – Equal', value: '1' },
                { displayText: 'Consistency Complaint – Curexa', value: '2' },
                { displayText: 'Damaged Pump – Curexa', value: '3' },
                { displayText: 'Wrong Address – Partner', value: '4' },
              ]}
              toOption={(x) => ({
                displayText: x.displayText,
                value: x,
                id: x.displayText,
              })}
            />
          </FormRow>
        )}
        <FormRow>
          <TextAreaInput required name="replacementReason" label={t('pharmacy:reason')} />
        </FormRow>
        <AddressParts
          address={
            pharmacyOrder.overriddenAddress || {
              address1: pharmacyOrder.address1,
              address2: pharmacyOrder.address2,
              city: pharmacyOrder.city,
              country: pharmacyOrder.country,
              phone: pharmacyOrder.phone,
              state: pharmacyOrder.state,
              zip: pharmacyOrder.zip,
            }
          }
        />
        <FormRow>
          <CheckboxInput required label={t('pharmacy:expediteReplacement')} name="expediteReplacement" />
        </FormRow>
      </FormSection>
      <BtnPrimary sx={{ m: 0 }} type="submit">
        {t('pharmacy:send')}
      </BtnPrimary>
    </ConnectedForm>
  );
};

export default ReplacementForm;

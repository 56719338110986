import { ChangeEvent } from 'react';
import { BtnPrimary, FormSection, Section, SectionContent } from '../common/ui-component';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  onUploadCSV: (file: File) => void;
};

export default function ConciergeCallListUploadDownloadSection({ onUploadCSV }: Props) {
  const { t } = useTranslation('case_fixer');

  const handleUploadCSV = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('here', event.target.files);

    if (event.target.files && event.target.files[0]) {
      onUploadCSV(event.target.files[0]);
    }
    event.target.value = '';
  };
  return (
    <Section title={t('concierge.title')}>
      <SectionContent>
        <p>{t('concierge.description')}</p>
      </SectionContent>
      <FormSection title={''}>
        <Divider>
          <BtnPrimary component="label">
            {t('concierge.cta')}
            <input
              style={{ display: 'none' }}
              name="file"
              type="file"
              accept=".csv"
              onChange={(event) => handleUploadCSV(event)}
              multiple={false}
            />
          </BtnPrimary>
        </Divider>
      </FormSection>
    </Section>
  );
}

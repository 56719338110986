import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type onClose = (onClose: () => void) => JSX.Element;

interface Props {
  children: JSX.Element | onClose;
  title: string;
  predicatedHash: string;
  defaultUrl: string;
  maxWidth?: 'lg' | 'xl';
  actions?: JSX.Element | JSX.Element[] | onClose;
  onClose?: () => void;
}

const RoutedModal = (props: Props) => {
  const { children, title, predicatedHash, defaultUrl, maxWidth, actions, onClose: propsOnclose } = props;
  const { hash } = useLocation();
  const navigate = useNavigate();
  const onClose = useCallback(
    (event?: object, reason?: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick') return;
      if (propsOnclose !== undefined) propsOnclose();
      return navigate(defaultUrl, { replace: true });
    },
    [propsOnclose, navigate, defaultUrl],
  );

  return (
    <Dialog
      open={hash === predicatedHash}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{typeof children === 'function' ? children(onClose) : children}</DialogContent>
      {actions && <DialogActions>{typeof actions === 'function' ? actions(onClose) : actions}</DialogActions>}
    </Dialog>
  );
};

export default RoutedModal;

const CASE_FIXER_EN = {
  caseFixer: 'Case Fixer',
  concierge: {
    description: 'Upload csv of concierge call list and csv with subscriptions will be return',
    cta: 'Upload Concierge CSV',
    title: 'Concierge Call List',
    download: 'Download',
    close: 'Close',
  },
};

export default CASE_FIXER_EN;

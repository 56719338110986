import { TFunction, useTranslation } from 'react-i18next';
import { Link, Icons } from '../common/ui-component';
import { StuckOrderModel } from '../models';
import { DateTime } from 'luxon';
import { _databaseFormat } from '../utils/date/helper';
import { Box } from '@mui/material';
import { CUSTOMERS_ROUTE, MEDICAL_CASE_ROUTE, ORDERS_ROUTE } from '../Router';
import { DataTableStatic, DateFormatter } from '../common/ui-component/data-table';
import FilterOperator from '../common/ui-component/data-table/filters/filter-operator-pro';
import { GridColDef, GridFilterModel, GridLogicOperator, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DefaultQueryParams } from '../utils/query';
import { RemoteData } from '../utils/remote-data';
import { Paginated } from '../common/Paginated';
import OrderStatusBadge from '../orders/OrderStatusBadge';

const calculate = (datetime: DateTime, t: TFunction<'stuck_order', undefined>) => {
  const daysAgo = Math.ceil(Math.abs(datetime.diffNow('days').days));
  if (daysAgo > 0) return t('dayAgo', { count: daysAgo });

  return t('hourAgo', { count: Math.floor(Math.abs(datetime.diffNow('hours').hours)) });
};

const getColumns = (t: TFunction<'stuck_order', undefined>): GridColDef[] => [
  {
    field: 'id',
    headerName: t('id'),
  },
  {
    field: 'stuckDate',
    headerName: t('stuckDate'),
    renderCell: (params: GridRenderCellParams) =>
      calculate(DateTime.fromFormat(params.row.stuckDate, _databaseFormat), t),
    sortable: false,
    disableColumnMenu: true,
    filterable: false,
  },
  {
    field: 'orderType',
    headerName: t('orderType'),
    filterOperators: FilterOperator.getGridSelectOperators(),
    type: 'singleSelect',
    valueOptions: [
      { value: 'Renewal', label: t('renewal') },
      { value: 'Parent', label: t('parent') },
      { value: 'One-Off', label: t('oneOff') },
    ],
  },
  {
    field: 'stuckReason',
    headerName: t('stuck'),
    filterOperators: FilterOperator.getGridSelectOperators(),
    type: 'singleSelect',
    valueOptions: [
      { value: 'stuck_before_mdi', label: t('stuckBeforeMdi') },
      { value: 'stuck_before_pharmacy', label: t('stuckBeforePharmacy') },
      { value: 'stuck_in_pharmacy', label: t('stuckPharmacy') },
    ],
    renderCell: (params: GridRenderCellParams) => {
      switch (params.value) {
        case 'stuck_before_mdi':
          return t('beforeMdi');
        case 'stuck_before_pharmacy':
          return t('beforePharmacy');
        case 'stuck_in_pharmacy':
          return t('inPharmacy');
        default:
          return 'N/A';
      }
    },
  },
  {
    field: 'errors',
    headerName: t('errors'),
    filterable: false,
    sortable: false,
  },
  {
    field: 'orderId',
    headerName: t('orderId'),
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`${ORDERS_ROUTE}/${params.value}`}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.value}
          <Icons.Launch></Icons.Launch>
        </Box>
      </Link>
    ),
  },
  {
    field: 'customerId',
    headerName: t('customerId'),
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`${CUSTOMERS_ROUTE}/${params.value}`}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.value}
          <Icons.Launch></Icons.Launch>
        </Box>
      </Link>
    ),
  },
  {
    field: 'medicalCaseId',
    headerName: t('medicalCaseId'),
    renderCell: (params: GridRenderCellParams) => (
      <Link to={`${MEDICAL_CASE_ROUTE}/${params.value}`}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {params.value}
          <Icons.Launch></Icons.Launch>
        </Box>
      </Link>
    ),
  },
  {
    field: 'subscriptionIds',
    headerName: t('subscriptionIds'),
  },
  {
    field: 'status',
    headerName: t('status'),
    renderCell: (params: GridRenderCellParams) => {
      return <OrderStatusBadge status={params.value} />;
    },
  },
  {
    field: 'mdiStatus',
    headerName: t('mdiStatus'),
  },
  {
    field: 'pharmacyStatus',
    headerName: t('pharmacyStatus'),
  },
  {
    field: 'customerName',
    headerName: t('customerName'),
  },
  {
    field: 'orderCreated',
    headerName: t('created'),
    filterOperators: FilterOperator.getGridDateOperators(),
    valueFormatter: DateFormatter.normalize,
  },
  {
    field: 'orderCreatedWithTime',
    headerName: t('createdWithTime'),
    filterOperators: FilterOperator.getGridDateOperators(),
    valueFormatter: DateFormatter.withTime,
    sortable: false,
    filterable: false,
  },
];

interface Props {
  listId: string;
  defaultParams?: DefaultQueryParams;
  defaultFilters?: GridFilterModel;
  getStuckOrders: (params: unknown) => Promise<RemoteData<Paginated<StuckOrderModel>, unknown>>;
  orders: RemoteData<Paginated<StuckOrderModel>, unknown>;
  hasToolbar?: boolean;
  checkboxSelection?: boolean;
}

function StuckOrders({ listId, getStuckOrders, orders, hasToolbar = true, checkboxSelection = true }: Props) {
  const { t } = useTranslation('stuck_order');

  return (
    <DataTableStatic
      id={listId}
      cols={getColumns(t)}
      fetcher={getStuckOrders}
      datas={orders}
      toRow={(stuckOrder: StuckOrderModel) => ({
        orderCreatedWithTime: stuckOrder.orderCreated,
        ...stuckOrder,
      })}
      initialColumnVisibilityModel={{ orderCreatedWithTime: false }}
      defaultFilters={{
        logicOperator: GridLogicOperator.And,
        items: [
          {
            field: 'orderCreated',
            operator: 'after',
            value: DateTime.now().minus({ days: 7 }).toFormat(_databaseFormat),
          },
          { field: 'orderCreated', operator: 'before', value: DateTime.now().toFormat(_databaseFormat) },
        ],
      }}
      checkboxSelection={checkboxSelection}
      hasToolbar={hasToolbar}
    />
  );
}

export default StuckOrders;

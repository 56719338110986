import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { TFunction, useTranslation } from 'react-i18next';
import ProductService from './product.service';
import { BtnDanger, DataTablePro as DataTable, Icons, Title } from '../common/ui-component';
import { Product, ProductCategory, ProductMedication } from './product.entity';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import { Alert, Snackbar } from '@mui/material';
import { notAsked } from '../utils/remote-data';

type Fns = { syncFromWoo: (id: string) => void; syncProduct: (id: string) => void };

const getActions =
  (t: TFunction<'product', undefined>, fns: Fns) =>
  ({ row }: GridRowParams) =>
    [
      <BtnDanger onClick={() => fns.syncFromWoo(row.wooCommerceId.toString())}>
        <Icons.Sync />
        {t('fromWoo')}
      </BtnDanger>,
      <BtnDanger onClick={() => fns.syncProduct(row.id.toString())}>
        <Icons.Sync />
        {t('sync')}
      </BtnDanger>,
    ];
const getColumns = (t: TFunction<'product', undefined>, fns: Fns): GridColDef[] => [
  {
    field: 'sku',
    headerName: t('sku'),
  },
  {
    field: 'wooCommerceId',
    headerName: t('wooCommerceId'),
  },
  {
    field: 'name',
    headerName: t('name'),
  },
  {
    field: 'distributor',
    headerName: t('pharmacy'),
  },
  {
    field: 'mdiTitle',
    headerName: t('mdiTitle'),
    renderCell: (params: GridRenderCellParams<Product>) => {
      const medications = params.row?.medications;
      if (!Array.isArray(medications)) return '';
      return ((medications as ProductMedication[]) || [])
        .map((m) => m.title)
        .filter(Boolean)
        .join(', ');
    },
  },
  {
    field: 'mdiId',
    headerName: t('mdiId'),
    renderCell: (params: GridRenderCellParams<Product>) => {
      const medications = params.row?.medications;
      if (!Array.isArray(medications)) return '';
      return ((medications as ProductMedication[]) || [])
        .map((m) => ('partner_medication_id' in m ? m.partner_medication_id : m.partner_compound_id))
        .filter(Boolean)
        .join(', ');
    },
  },
  {
    field: 'categories',
    headerName: t('category'),
    renderCell: (params: GridRenderCellParams<ProductCategory[]>) => {
      if (!params.value?.length) return '';
      return params.value.some((c: ProductCategory) => c.name === 'Rx') ? 'Rx' : 'OTC';
    },
  },
  {
    field: 'hasImage',
    headerName: t('hasImage'),
    renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Checked /> : <Icons.Cancel />),
  },
  {
    field: 'created',
    headerName: t('created'),
  },
  {
    field: 'inBlueshift',
    headerName: t('inBlueshift'),
    renderCell: (params: GridRenderCellParams) => (params.value ? <Icons.Checked /> : <Icons.Cancel />),
  },
  {
    field: 'createdWithTime',
    headerName: t('createdWithTime'),
  },
  {
    field: 'actions',
    type: 'actions',
    getActions: getActions(t, fns),
  },
];

type ProductWith = Product & { inBlueshift: boolean };

const Products = () => {
  const { t } = useTranslation('product');
  const [fetchProducts, rProducts] = ProductService.useSearchProductsWithBlueshiftSync();
  const [syncFromWoo, rSyncFromWoo, setRSyncFromWoo] = ProductService.useSyncFromWoo();
  const [syncProduct, rSync, setRSync] = ProductService.useProductSync();

  return (
    <>
      <Title>{t('products')}</Title>

      <DataTable
        id="product-table"
        cols={getColumns(t, { syncFromWoo, syncProduct })}
        fetcher={fetchProducts}
        datas={rProducts}
        toRow={(pProduct: ProductWith) => ({
          ...pProduct,
          createdWithTime: pProduct.created,
          hasImage: !!pProduct.images.length,
        })}
        toCsvRow={(pProduct: ProductWith) => ({
          ...pProduct,
          categories: (pProduct.categories || []).map((c) => c.name).join(', '),
        })}
        initialColumnVisibilityModel={{ createdWithTime: false }}
        checkboxSelection
      />
      <RemoteDataContent value={rSyncFromWoo}>
        <RemoteDataContent.Success>
          {() => (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setRSyncFromWoo(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          )}
        </RemoteDataContent.Success>
        <RemoteDataContent.NotAsked>
          <></>
        </RemoteDataContent.NotAsked>
      </RemoteDataContent>
      <RemoteDataContent value={rSync}>
        <RemoteDataContent.Success>
          {() => (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setRSync(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          )}
        </RemoteDataContent.Success>
        <RemoteDataContent.NotAsked>
          <></>
        </RemoteDataContent.NotAsked>
      </RemoteDataContent>
    </>
  );
};

export default Products;

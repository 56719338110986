export enum PharmacyNameEnum {
  CUREXA = 'curexa',
  CHEMISTRY_RX = 'chemistryrx',
  HAPPYHEAD = 'happyhead',
  MINIMUS = 'minimus',
}

export enum PharmacyOrderStatusEnum {
  NEW = 'new',
  IN_PROGRESS = 'in_progress',
  OUT_FOR_DELIVERY = 'out_for_delivery',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  ERROR = 'error',
}

export enum CurexaOrderStatusDetailsEnum {
  BAD_ADDRESS = 'Bad Address',
  DUPLICATE = 'Duplicate Order',
  MISSING_RX = 'Missing Electronic Prescription',
  REFILL_TO_SOON = 'Refill too Soon',
  RETURNER = 'Returned to Sender',
  ALLERGIES = 'Clarification Required:  Allergies',
  OTHER_MEDICATIONS = 'Clarification Required:  Other Medications',
  RX_EXPIRED = 'Prescription Expired',
  NO_REFILLS = 'No Refills Remaining',
}

export const CancellableResendableError = [
  CurexaOrderStatusDetailsEnum.DUPLICATE,
  CurexaOrderStatusDetailsEnum.MISSING_RX,
  CurexaOrderStatusDetailsEnum.REFILL_TO_SOON,
  CurexaOrderStatusDetailsEnum.RETURNER,
  CurexaOrderStatusDetailsEnum.RX_EXPIRED,
  CurexaOrderStatusDetailsEnum.NO_REFILLS,
];

export enum OrderStatusEnum {
  PENDING_PAYMENT = 'pending-payment',
  PENDING_PHOTOS = 'pending-photo',
  AWAITING_CASE = 'awaiting-case',
  IN_REVIEW = 'in-review',
  CASE_PROCESSING = 'case-processing',
  PROCESSING = 'processing',
  APPROVED = 'approved',
  CANCELLED_BY_CUSTOMER = 'cancelled-by-customer',
  CANCELLED_BY_DOCTOR = 'cancelled-by-doctor',
  SUPPORT = 'support',
  REFUNDED = 'refunded',
  FAILED = 'failed',
  COMPLETED = 'completed',
  ON_HOLD = 'on-hold',
  PRE_TRANSIT = 'shipping-pre-transit',
  TRANSIT = 'shipping-transit',
  DELIVERED = 'shipping-delivered',
  RETURNED = 'shipping-returned',
  FAILURE = 'shipping-failure',
  PHARMACY_ORDER_SENT = 'pharmacy-order-sent',
  PHARMACY_ORDER_RECEIVED = 'pharmacy-order-received',
  PHARMACY_ORDER_PROCESSING = 'pharmacy-order-processing',
  PHARMACY_ORDER_DELIVERING = 'pharmacy-order-delivering',
  PHARMACY_ORDER_COMPLETED = 'pharmacy-order-completed',
  PHARMACY_ORDER_CANCELLED = 'pharmacy-order-cancelled',
  PHARMACY_ORDER_ERROR = 'pharmacy-order-error',
}

export interface PharmacyOrder {
  id: number;
  orderId: number;
  version: number;
  patientId: string;
  firstName: string;
  lastName: string;
  hasBeenApprovedByDoctor: boolean;
  hasBeenPayed: boolean;
  hasAlreadyBeenShipped: boolean;
  hasBeenRefunded: boolean;
  rxItems: RxItem[];
  otcItems: OtcItem[];
  status: PharmacyOrderStatusEnum | 'not-sent';
  statusDetails: CurexaOrderStatusDetailsEnum | null;
  resendCount: number;
  pharmacyName: PharmacyNameEnum;
  trackingNumber: string;
  shippingMethod: string;
  overriddenAddress: PharmacyAddress;
  address1: string;
  address2: string;
  city: string;
  country: string;
  phone: string;
  state: string;
  zip: string;
  clinician: Clinician | null;
  trackingInfo: {
    trackingNumber: string;
  }[];
  updated: string;
  created: string;
  carrier: 'USPS';
}

export interface PharmacyAddress {
  company: string;
  firstName: string;
  lastName: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

export interface Clinician {
  firstName: string;
  lastName: string;
}
export interface OtcItem {
  name: string;
  quantity: string;
  sku: string;
  replacedOrderId?: string;
  replacementReason?: string;
  replacementResponsibleParty?: number;
  replaced?: boolean;
}

export interface RxItem {
  rxId: string;
  sku?: string;
  prescription: {
    medicationName: string;
    quantityDispensed: number;
    daysSupply: number;
    prescribingDoctor: string;
    medicationSig: string;
    nonChildResistantAcknowledgment: boolean;
    isRefill?: boolean;
    treatmentType?: string;
    compoundBase?: string;
    prefPackaging?: string;
    isReplacement?: string;
    replacedOrderId?: string;
    replacementReason?: string;
    replacementResponsibleParty?: number;
    replaced?: boolean;
  };
}

export const getTrackingUrl = (carrier: string, tracking_number: string): string => {
  if (carrier === 'USPS') return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${tracking_number}`;
  else if (carrier === 'fedex') return `https://www.fedex.com/wtrk/track/?trknbr=${tracking_number}`;
  else return 'none';
};

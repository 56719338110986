import { BtnDanger } from '../common/ui-component';
import { useFormContext } from 'react-hook-form';

const ClearAllProductRecommendationButton = () => {
  const { setValue } = useFormContext();
  return (
    <BtnDanger
      onClick={() => {
        setValue('dateOfBirth', null);
        setValue('userChosenPids', []);
        setValue('userPreferredMethods', []);
        setValue('gender', null);
      }}
    >
      Clear All Values
    </BtnDanger>
  );
};

export default ClearAllProductRecommendationButton;

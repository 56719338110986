import axios from 'axios';
import { Paginated } from '../common/Paginated';
import useService from '../common/useService';
import { PageProps } from '../common/ui-component/data-table/filters/backoffice-query';
import { SubscriptionModel } from '../models';

const useSubscriptionsService = () =>
  useService((params: PageProps) =>
    axios.get<Paginated<SubscriptionModel>>(`/v2/subscriptions`, {
      params,
    }),
  );

const useGetSubscription = () => useService((id: string) => axios.get<SubscriptionModel>(`/subscriptions/${id}`));
const useSyncSubscription = () =>
  useService((id: string | number) => axios.get<SubscriptionModel>(`/subscriptions/${id}/sync`));

const useUpdateSubscription = () =>
  useService(({ id, subscription }: { id: string | number; subscription: SubscriptionModel }) =>
    axios.put<SubscriptionModel>(`/subscriptions/${id}`, { subscription }),
  );

const useSyncSubscriptionWithWoo = () => useService((id: number) => axios.post(`/subscriptions/${id}/sync`));

const useUploadDownloadConcierge = () =>
  useService(async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const r = await axios.post<Blob>('/subscriptions/call-list-from-csv', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'blob',
    });

    // const href = URL.createObjectURL(r.data);

    // // create "a" HTML element with href to file & click
    // const link = document.createElement('a');
    // link.href = href;
    // link.setAttribute('download', `${file.name}-with-subscriptions.csv`); //or any other extension
    // document.body.appendChild(link);
    // link.click();

    // // clean up "a" element & remove ObjectURL
    // document.body.removeChild(link);
    // URL.revokeObjectURL(href);
    return { ...r, data: { name: file.name, blob: r.data } };
  });

const SubscriptionsService = {
  useSubscriptionsService,
  useGetSubscription,
  useUpdateSubscription,
  useSyncSubscription,
  useSyncSubscriptionWithWoo,
  useUploadDownloadConcierge,
};

export default SubscriptionsService;
